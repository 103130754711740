<template>
  <b-modal
    v-model="trigger"
    :id="id"
    :ok-title="okTitle"
    :title="title"
    @cancel="cancel"
    :centered="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :hide-header-close="true"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="modal-close">
      <span class="Modal-head-text">{{ title }}</span>
      <p class>
        <img
          @click="cancel()"
          class="cursor-pointer modal-close-button mt-n1"
          style="float:right"
          src="@/assets/CloseModel.svg"
        />
      </p>
    </div>
    <div class="p-3 mt-3">
      <label class="mb-1 p-0 label-class">Select Pickup Slot</label>
      <w-dropdown
        style="width: 250px;"
        :label-text="''"
        :selectedValue="selectedShipmentSlot"
        :selectOption="shipmentSheduleList"
        @selectedOption="getSelectedShipmentShedule($event)"
      />
    </div>
    <div class="row p-3">
      <b-button
        variant="primary"
        class="col-md-3 ml-2"
        style="height: 35px"
        @click="confirmOk"
        :disabled="!selectedShipmentSlot"
        >Schedule</b-button
      >
      <div class="col-md-5"></div>
      <b-button
        variant="danger"
        class="col-md-3 mr-2"
        style="height: 35px"
        @click="cancel"
        >Cancel</b-button
      >
    </div>
  </b-modal>
</template>
<script>
import Dropdown from "./Dropdown.vue";

export default {
  props: ["trigger", "shipmentSheduleList", "title", "selectedShipmentSlot"],
  components: {
    "w-dropdown": Dropdown,
  },
  methods: {
    getIconStyle(alert) {
      switch (alert) {
        case "Success":
          return "background-color: #47d764;border-radius: 100%; padding: 2px;";
        case "Failure":
          return "height:30px;width:30px";
      }
    },
    confirmOk() {
      this.$emit("confirmOkEvent");
    },
    cancel() {
      this.$emit("eventClose");
    },
    getSelectedShipmentShedule(event) {
      this.$emit("selectedShipmentSheduleEvent", event);
    },
    getImage(image) {
      var images = require.context("../assets/alert-msg/", false, /\.svg$/);
      return images("./" + image + ".svg");
    },
  },
};
</script>

<style>
.Modal-head-text {
  color: white;
  font-size: 13px;
  float: left;
}
.modal-content {
  width: 100%;
  border-radius: 0 !important;
}
.modal-close {
  padding: 6px !important;
  color: #fff;
  /* background: #0071c1; */
}
.modal-body {
  padding: 0;
}
.modal-close-button {
  border: solid 1px #707070;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 50%;
  background-color: rgb(255, 255, 255) !important;
  margin: 0.5px 0px 0px 5px !important;
  width: 18px;
}
.modal-backdrop {
  opacity: 0.5 !important;
}
.align-class {
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-key {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #191d24;
}
.alert-msg {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.36px;
  text-align: left;
  color: #898b91;
}
</style>
