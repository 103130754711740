import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const baseUrl = process.env.VUE_APP_ORDER_SERVER_URL;
const shipmentBaseUrl = process.env.VUE_APP_SHIPMENT_SERVER_URL;
const baseUserUrl = process.env.VUE_APP_USER_SERVER_URL;
const channelServiceUrl = process.env.VUE_APP_CHANNEL_SERVER_URL;

export const getOrderById = (companyId = 7, channelId = 1, masterOrderId = 1) => {
    const uri = `${baseUrl}/api/v1/companies/channels/${channelId}/orders/${masterOrderId}`;  
    return apiHelper.get(uri);
}

export const getOrderStatus = () => {
    const uri = `${baseUrl}/api/v1/order/order-status`;
    return apiHelper.get(uri);
}

export const getShipmentStatus = () => {
    const uri = `${baseUrl}/api/v1/order/shipment-batch-status`;
    return apiHelper.get(uri);
}

export const getShipmentListWithFilter = (companyId, pageNumber = 0, pageSize = 2, startDate, endDate, shipmentId, channelIds, shipmentTypes, status, createdBy) => {
    let uri = `${baseUrl}/api/v1/companies/order-shipment-batches?page-no=${pageNumber}&page-size=${pageSize}`; 
    if (startDate) {
        uri = uri.concat('&created-on-start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&created-on-end-date=', endDate);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-ids=', channelIds);
    }
    if (shipmentTypes && shipmentTypes.length > 0) {
        uri = uri.concat('&shipment-types=', shipmentTypes);
    }
    if (status.length > 0) {
        uri = uri.concat('&statuses=', status);
    }
    if (createdBy != null) {
        uri = uri.concat('&created-by=', createdBy);
    }
    if (shipmentId) {
        uri = uri.concat('&shipment-batch-ids=', shipmentId);
    }
    return apiHelper.get(uri);
}

export const getShipmentListByOrderId = (orderShipmentBatchId, companyId, pageNumber, pageSize, startDate, endDate, pickupStartDate, pickupEndDate, status, orderId, skuId) => {
    let uri = `${baseUrl}/api/v1/companies/order-shipment-batches/${orderShipmentBatchId}/orders?page-no=${pageNumber}&page-size=${pageSize}&company-id=${companyId}`; // to be updated (remove company id)
    if (startDate) {
        uri = uri.concat('&start-ship-by-from-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&end-ship-by-from-date=', endDate);
    }
    if (orderId) {
        uri = uri.concat('&order-id=', orderId);
    }
    if (skuId) {
        uri = uri.concat('&sku=', skuId);
    }
    if (status.length > 0) {
        uri = uri.concat('&statuses=', status);
    }
    return apiHelper.get(uri);
}

//Get Shipment by ID
export const getShipmentById = (companyId, shipmentId) => {
    const uri = `${baseUrl}/api/v1/companies/order-shipment-batches/${shipmentId}`  
    return apiHelper.get(uri);
}

export const getShipmentTypes = () => {
    const uri = `${baseUrl}/api/v1/orders/shipment-type`;
    return apiHelper.get(uri);
}

export const markShippedBulk = (companyId, data) => {
    const uri = `${baseUrl}/api/v1/companies/order-shipment-batches/mark-shipped-in-bulk`;  
    return apiHelper.put(uri, data);
}

export const deleteOrderInShipment = (orderShipmentBatchId, companyId, orderIds) => {
    const uri = `${baseUrl}/api/v1/companies/order-shipment-batches/${orderShipmentBatchId}/orders?order-ids=${orderIds}`;
    return apiHelper.remove(uri);
}

export const deleteShipment = (shipmentIds) => {
    const uri = `${baseUrl}/api/v1/companies/order-shipment-batches?shipment-ids=${shipmentIds}`;
    return apiHelper.remove(uri);
}

export const schedulePickUp = (companyId, orderIds) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/easyship/pick-up-slots?order-id=${orderIds}`;
    return apiHelper.get(uri);
}

export const schedulePickUpSlot = (pickupSlotId, pickupStart, pickupEnd, orderIds, shipmentId, companyId, channelId) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/easyship/create-schedule?pickupslot-id=${pickupSlotId}&pickup-start=${pickupStart}&pickup-end=${pickupEnd}&order-ids=${orderIds}&shipment-id=${shipmentId}&company-id=${companyId}&channel-id=${channelId}`;
    return apiHelper.post(uri);

}

export const downLoadInvoices = (orderId) => {
    // const uri = `${shipmentBaseUrl}/api/v1/easyship/labelandinvoice/download?orderId=${orderId}&fileType=INVOICE`;
    const uri = `${shipmentBaseUrl}/api/v1/easyship/order/invoice?order-id=${orderId}`; 
    return apiHelper.downloadFile(uri);
}

export const downLoadInvoicesAndLabels = (orderId) => {
    const uri = `${shipmentBaseUrl}/api/v1/easyship/order/label-invoice?order-id=${orderId}`;
    return apiHelper.downloadFile(uri);
}

export const downLoadLabels = (orderId) => {
    // const uri = `${shipmentBaseUrl}/api/v1/easyship/labelandinvoice/download?orderId=${orderId}&fileType=LABEL`;
    const uri = `${shipmentBaseUrl}/api/v1/easyship/order/label?order-id=${orderId}`;
    return apiHelper.downloadFile(uri);
}

// UPLOAD BUTTON SECTION

export const uploadShipmentTable = (pageNumber = 0, pageSize = 2) => {
    const uri = `${shipmentBaseUrl}/api/v1/bulk-shipment-details?page-no=${pageNumber}&size=${pageSize}`;
    return apiHelper.get(uri);
}

export const  downloadProcessingReport = (batchId) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/download-process-report?batch-id=${batchId}`;
    return apiHelper.downloadFile(uri);
}

export const downLoadShipmentLabels = (batchId) => {
    // const uri = `${shipmentBaseUrl}/api/v1/easyship/labelandinvoice/batch/download?batch-id=${batchId}&fileType=BATCH`;
    const uri = `${shipmentBaseUrl}/api/v1/easyship/order/bulk-label?batch-id=${batchId}`;
    return apiHelper.downloadFile(uri);
}

// DOWNLOAD TEMPLATE BUTTON for marketplace 
export const downLoadTemplate = (orderIDs, channelID, shipmentId) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/template/download?order-id=${orderIDs}&channel-id=${channelID}&shipment-id=${shipmentId}`;
    
    return apiHelper.downloadFile(uri);
}
//  upload shipment template
export const uploadShipmentTemplate = (formData, shipmentId, channelId) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/import?shipment-id=${shipmentId}&channel-id=${channelId}`;
    return apiHelper.post(uri, formData);
  }

//   Download selfship template
export const downLoadEasyShip = (orderIDs, channelID, shipmentId, companyIds,) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/template-download?channel-id=${channelID}&shipment-id=${shipmentId}&order-id=${orderIDs}&company-id=${companyIds}`;
    return apiHelper.downloadFile(uri);
}
//  upload shipment template
export const uploadSelfShipTemplate = (formData, selfshipmentId, channelId) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/import?shipment-id=${selfshipmentId}&channel-id=${channelId}`;
    return apiHelper.post(uri, formData);
  }

// selfship uplaod template table data
export const uploadSelfShipTableData = (pageNumber = 0, pageSize = 2) => {
    const uri = `${shipmentBaseUrl}/api/v1/selfship/bulk-shipment-details?page-no=${pageNumber}&size=${pageSize}`; 
    return apiHelper.get(uri);
}

// download processing report in selfship
export const downloadSelfshipProcessingReport = (batchID) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/download-process-report?batch-id=${batchID}`; 
    return apiHelper.downloadFile(uri);
}

export const downloadSelfshipLabels = (batchID) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/download/label?batch-id=${batchID}`; 
    return apiHelper.downloadFile(uri);
}

// schedule ship rocket
export const scheduleShipRocket = (shipmentID) =>{
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/courier/generate/pickup?shipment-id=${shipmentID}`;
    return apiHelper.post(uri);
}
// download generate manifest file
export const downloadManifestFile = (shipmentID) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/download/manifest?shipment-id=${shipmentID}`;  
    return apiHelper.get(uri);
}

// download template for confirm shipment 
export const downloadConfirmShipment = (shipmentID) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/confirmation-template/download?shipment-id=${shipmentID}`;  
    return apiHelper.downloadFile(uri);
}
// api call for get table data in confirm shipment 
export const getConfirmShipmentDetail = (pageNumber, pageSize) => {
    // const uri = `${shipmentBaseUrl}/api/v1/bulk-confrimation-details?page-no=${pageNumber}&page-size=${pageSize}`;
    const uri = `${shipmentBaseUrl}/api/v1/shipment/bulk-confrimation-details?page-no=${pageNumber}&page-size=${pageSize}`;
    return apiHelper.get(uri);
}

// when we click on View link in shipment detatil table
export const viewPickSlotDetails = (orderId) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/courier/pickup_details?order-id=${orderId}`;
    return apiHelper.get(uri);
}

// for created by dropdown 
export const getCreatedByList = () => {
    const uri = `${baseUserUrl}/v1/users`;
    return apiHelper.get(uri);
}

// for cancel button
export const cancelShipmentOrders = (orderIds) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/courier/orders/cancel?order-id=${orderIds}`;  
    return apiHelper.post(uri);
}

// upload confirm shipment template 
export const uploadConfirmTemplate = (formData, channelId) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/selfship/confirmation-template/upload?channel-id=${channelId}`;  
    return apiHelper.post(uri, formData);
}

// download selfship confirm processing report
export const downloadConfirmProcessingReport = (batchId) => {
    const uri = `${shipmentBaseUrl}/api/v1/shipment/awb-confrim-ops/download-process-report?batch-id=${batchId}`;  
    return apiHelper.downloadFile(uri);
}

// download selfship Labels
export const labelDownloadForSelfship = (orderId, channelOrderId) => {
    const uri = `${shipmentBaseUrl}/api/v1/easyship/order/label?order-id=${channelOrderId}`;  
    return apiHelper.downloadFile(uri);
}

// download selfship Invoices
export const InvoiceDownloadForSelfship = (orderId, channelOrderId) => {
    const uri = `${shipmentBaseUrl}/api/v1/easyship/order/invoice?order-id=${channelOrderId}`;  
    return apiHelper.downloadFile(uri);
}

// get shipment auto schedule settings
export const getAutoScheduleSettings = (channelId) => {
    const uri = `${channelServiceUrl}/api/v1/companies/get/auto-schedule-setting/channels/${channelId}`;
    return apiHelper.get(uri);
}

// update shipment auto schedule settings
export const updateAutoScheduleSettings = (formData, channelId) => {
    const uri = `${channelServiceUrl}/api/v1/companies/update/auto-schedule-setting/channels/${channelId}`;  
    return apiHelper.put(uri, formData);
}
