<template>
  <div>
    <div v-if="!isOrderDetailsTab" class="whithout-tab-content">
      <!-- refresh with back button -->
      <div class="row px-2 my-2">
        <div class="col-lg-5 col-md-5 col-8">
          <!-- <span class="summary_refresh_text"
          >Last Updated On: {{ lastUpdated ? lastUpdated : "-" }}</span> -->
          <!-- &nbsp;&nbsp;<span class="summary_refresh">
            <img
              style="cursor:pointer"
              @click="loadData()"
              src="../../assets/refresh.png"
              width="15px"
            />
          </span> -->
        </div>
        <div class="col-lg-7 col-md-7 col-4 text-right">
          <img
            src="../../assets/SVG/Back.svg"
            width="60px"
            class="back-button"
            @click="goBackToShipment"
          />
        </div>
      </div>

      <b-card class="m-2 shipment-details">
        <div class="row p-0 m-0 detail-column">
          <div
            class="col-lg-2 col-md-2 col-6 p-0 m-0"
            v-for="tab in shipmentTabs"
            :key="tab"
          >
            <span class="shipment-heading-class">{{ tab.text }}</span
            ><br />
            <span class="shipment-value-class">{{ tab.value }}</span>
          </div>
        </div>
      </b-card>

      <b-card class="m-2" :body-class="'card-padding'" v-if="isDesktopFilter">
        <div class="row shipment-filter">
          <img
            class="mt-3 close-filter-icon"
            src="../../assets/CloseModel.svg"
            width="15px"
            @click="openDesktop_filter"
          />
          <div class="col-md-3">
            <label class="tree-label">OrderID/Channel Order ID</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="orderId"
              :labelStyle="'register-label'"
              @textInputChange="getOrderId($event)"
            />
          </div>
          <!-- <div class="col-md-3">
              <label class="ml-3 tree-label">Pickup Date</label>
              <date-picker
                v-model="selectedPickupDate"
                class="ml-3"
                :lang="'en'"
                range
                confirm
                :shortcuts="[]"
                @clear="clearPickupDate($event)"
                @confirm="getPickupDate($event)"
              ></date-picker>
            </div> -->
          <div class="col-md-3 shipBy-date">
            <label class="tree-label">Ship By</label>
            <date-picker
              v-model="selectedPurchaseDate"
              :lang="'en'"
              range
              confirm
              :shortcuts="[]"
              @clear="clearPurchaseDate($event)"
              @confirm="getPurchaseDate($event)"
            ></date-picker>
          </div>
          <div class="col-md-3">
            <TreeDropdown
              :labelText="'Status'"
              :rootOptions="orderStatus"
              :selectedValue="selectedstatusList"
              @selectedField="getSelectedstatus($event)"
            />
          </div>
          <div class="col-md-3">
            <label class="tree-label">SKU</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="skuId"
              :labelStyle="'register-label'"
              @textInputChange="getSkuId($event)"
            />
          </div>
        </div>
      </b-card>

      <div class="shipment-detail-table">
        <w-orders-table
          :tableLoading="tableLoading"
          :tableProperties="tableProperties"
          @showDeleteOption="showDeleteOption"
          @receive="receive($event)"
          @getSelectedPerPageEvent="getSelectedPerPage($event)"
          @getSelectedPageEvent="getSelectedPage($event)"
          @goToShipmentDetailsPageEvent="goToShipmentDetailsPage($event)"
          @deleteOrderInShipmentEvent="showConfirmModal('Delete')"
          @ShipDetailsSchedulePickUpEvent="shipDetailsSchedulePickUp"
          @goToOrderDetailsPageEvent="goToOrderDetailsPage($event)"
          @downLoadShipDetailsInvoicesEvent="
            downLoadShipDetailsInvoices($event)
          "
          @downLoadShipDetailsInvoicesAndLabelsEvent="
            downLoadShipDetailsInvoicesAndLabels($event)
          "
          @downLoadShipDetailsLabelsEvent="downLoadShipDetailsLabels($event)"
          :shipmentType="shipmentTabs[4].value"
          :pickUpSlotDATA="pickUpSlotDATA"
          @showPickUpTimeDetailEvent="showPickUpTimeDetail($event)"
          @openDesktop_filterEvent="openDesktop_filter"
          :isFilterButton="true"
          @openUploadModel="openUploadModel($event)"
          @downloadShipmentTemplate="downloadShipmentTemplate($event)"
          @scheduleShipRocketOrder="scheduleShipRocketOrder"
          @downloadGenerateManifest="downloadGenerateManifest"
          @cancelShipmentOrders="cancelShipmentOrders"
          @downloadConfirmShipment="downloadConfirmShipment"
        />
      </div>
    </div>
    <div v-else>
      <w-Order-Details-Table
        :orderIdParams="orderDetialsParams.masterOrderId"
        :channelIdParams="orderDetialsParams.channelId"
        :companyIdParams="orderDetialsParams.companyId"
        @gotoBackPage="goBackToOrder()"
        :shipmentTabs="shipmentTabs"
      />
    </div>
    <Modal
      :trigger="alert || isPickUpDetail"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
      :isPickUpDetail="isPickUpDetail"
      :pickUPDetails="pickUPDetails"
    />
    <Shipment-Modal
      :trigger="shipmentShedulePickup"
      :title="'HectoCommerce'"
      :shipmentSheduleList="shipmentSheduleList"
      @eventClose="closeShipmentModal()"
      @confirmOkEvent="showConfirmModal('Shedule')"
      @selectedShipmentSheduleEvent="getSelectedShipmentShedule($event)"
      :selectedShipmentSlot="selectedShipmentSlot"
    />
    <w-loading-spinner :loadSpinner="loadingWidget" :isSchedulePickup="true" />

    <!-- upload easyship template -->
    <b-modal
      id="upload-easyship-template"
      size="xl"
      hide-footer="true"
      hide-header-close
      centered
    >
      <w-UploadEasyShipTemplate
        @goBackToPreviousPage="goBackToPreviousPage('easyshipTemplate')"
      />
    </b-modal>
    <!-- upload selfship template -->
    <b-modal
      id="upload-ConfirmShipment"
      size="xl"
      hide-footer="true"
      hide-header-close
      centered
    >
      <w-UploadConfirmShipment
        @goBackToPreviousPage="goBackToPreviousPage('confirmShipment')"
      />
    </b-modal>
    <!-- upload confirmshipment template -->
    <b-modal
      id="upload-SelfshipTemplate"
      size="xl"
      hide-footer="true"
      hide-header-close
      centered
    >
      <w-UploadSelfshipTemplate
        @goBackToPreviousPage="goBackToPreviousPage('selfshipTemplate')"
      />
    </b-modal>
  </div>
</template>

<script>
import ButtonInput from "../../widgets/InputButton.vue";
import DatePicker from "vue2-datepicker";
import TextInput from "../../widgets/TextInput.vue";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import TreeDropdown from "../../widgets/TreeDropdown";
import Modal from "../../widgets/ModalWidget";
import moment from "moment";
import ShipmentModal from "../../widgets/ShipmentModal";
import OrderDetailsTable from "../../widgets/OrderDetailsTables.vue";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import UploadShipment from "./UploadShipment.vue";
import UploadConfirmShipment from "./UploadShipmentConfirmation.vue";
import UploadSelfshipTemplate from "./UploadSelfShip.vue";
import {
  getShipmentById,
  getShipmentListByOrderId,
  deleteOrderInShipment,
  getOrderStatus,
  schedulePickUp,
  schedulePickUpSlot,
  downLoadInvoices,
  downLoadInvoicesAndLabels,
  downLoadLabels,
  downLoadTemplate,
  downLoadEasyShip,
  scheduleShipRocket,
  downloadManifestFile,
  downloadConfirmShipment,
  viewPickSlotDetails,
  cancelShipmentOrders,
  labelDownloadForSelfship,
  InvoiceDownloadForSelfship,
} from "../../Service/ShipmentService";
import moment_timezone from "moment-timezone";

export default {
  components: {
    "w-button-input": ButtonInput,
    DatePicker,
    "w-text-input": TextInput,
    "w-orders-table": TableWithCheckbox,
    TreeDropdown,
    Modal,
    "Shipment-Modal": ShipmentModal,
    "w-Order-Details-Table": OrderDetailsTable,
    "w-loading-spinner": LoadingSpinner,
    "w-UploadEasyShipTemplate": UploadShipment,
    "w-UploadConfirmShipment": UploadConfirmShipment,
    "w-UploadSelfshipTemplate": UploadSelfshipTemplate,
  },
  data() {
    return {
      isDesktopFilter: false, // for filter to open
      companyID: sessionStorage.getItem("companyIds"),
      // for shipment upload template we need channel id and company ids
      shimentIDs: {
        channelIds: sessionStorage.getItem("channelID"),
        companyIds: sessionStorage.getItem("companyID"),
        shipmentIds: sessionStorage.getItem("shipmentID"),
      },
      isPickUpDetail: false,
      pickUPDetails: "",
      loadingWidget: false,
      selectedShipmentSlot: "",
      orderDetialsParams: null,
      isOrderDetailsTab: false,
      tableLoading: false,
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      types: [],
      paymentTypes: [],
      signOutAlert: false,
      orderStatus: [],
      shipmentTabs: [
        {
          text: "Shipment ID",
          value: "",
        },
        {
          text: "Status",
          value: "",
        },
        {
          text: "Channel Name",
          value: "",
        },
        {
          text: "Created Date",
          value: "",
        },
        {
          text: "Shipment type",
          value: "",
        },
        {
          text: "No of orders",
          value: "",
        },
      ],
      categories: [],
      selectedStatusList: [],
      selectedPurchaseDate: "",
      selectedPickupDate: "",
      orderId: "",
      skuId: "",
      tableProperties: {
        fields: [
          {
            key: "checkbox",
            label: "",
            selected: false,
          },
          {
            key: "channelOrderId",
            label: "Order ID / Channel Order ID",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "shipByFromDate",
            label: "Ship By",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "pickupTime",
            label: "Pickup Time",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "quantity",
            label: "Quantity",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "orderProductMappingDtoList",
            label: "SKU/Title",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "customer",
            label: "Shipped To",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "totalAmount",
            label: "Order Total",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "orderStatus",
            label: "Status",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "actiond_field",
            label: "Action",
            selected: false,
            thClass: "thClass",
            tdClass: "tdClass",
          },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
        allSelected: false,
        shipmentDetailsShowButton: true,
      },
      // companyId: 7,
      startDate: "",
      endDate: "",
      pickupStartDate: "",
      pickupEndDate: "",
      orderShipmentBatchId: "",
      shipmentSheduleList: [],
      shipmentShedulePickup: false,
      popType: "",
    };
  },
  created() {
    if (sessionStorage.getItem("orderShipmentBatchId")) {
      this.orderShipmentBatchId = sessionStorage.getItem(
        "orderShipmentBatchId"
      );
      this.getOrderStatusList();
      this.getShipmentById();
      // this.loadData();
      this.getOrderList();
    }
  },
  methods: {
    // loadData() {
    //   this.getLastUpdated();
    //   this.getOrderList();
    // },
    // To get  last updated time
    // getLastUpdated() {
    //   this.lastUpdated = moment().format("MM/DD/YYYY hh:mm:ss a");
    // },

    downLoadShipDetailsInvoices(data) {
      if(data.shipType == "EASYSHIP"){
        if (data.item.channelOrderId) {
        this.loadingWidget = true;
        downLoadInvoices(data.item.channelOrderId)
          .then((res) => {
            this.loadingWidget = false;
          })
          .catch((err) => {
            this.loadingWidget = false;
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
        }
      }else {
        if (data.item.masterOrderId) {
        this.loadingWidget = true;
        InvoiceDownloadForSelfship(data.item.masterOrderId, data.item.channelOrderId)
          .then((res) => {
            this.loadingWidget = false;
            // location.href=res.invoice_url;
          })
          .catch((err) => {
            var self = this;
            // for read the blob response type
            var myReader = new FileReader();
            myReader.addEventListener("loadend", function (e) {
              self.alertMsg = JSON.parse(e.srcElement.result).detail;
            });
            //start the reading process.
            myReader.readAsText(err);

            this.loadingWidget = false;
            this.alert = true;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
        }
      }
    },

    downLoadShipDetailsInvoicesAndLabels(item) {
      if (item.channelOrderId) {
        this.loadingWidget = true;
        downLoadInvoicesAndLabels(item.channelOrderId)
          .then((res) => {
            this.loadingWidget = false;
          })
          .catch((err) => {
            var self = this;
            // for read the blob response type
            var myReader = new FileReader();
            myReader.addEventListener("loadend", function (e) {
              self.alertMsg = JSON.parse(e.srcElement.result).detail;
            });
            //start the reading process.
            myReader.readAsText(err);

            this.loadingWidget = false;
            this.alert = true;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
      }
    },
    downLoadShipDetailsLabels(data) {
      if(data.shipType == "EASYSHIP"){
        if (data.item.channelOrderId) {
        this.loadingWidget = true;
        downLoadLabels(data.item.channelOrderId)
          .then((res) => {
            this.loadingWidget = false;
          })
          .catch((err) => {
            var self = this;
            var myReader = new FileReader();
            myReader.addEventListener("loadend", function (e) {
              self.alertMsg = JSON.parse(e.srcElement.result).detail;
            });
            myReader.readAsText(err);

            this.loadingWidget = false;
            this.alert = true;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
        }
      }else {
        if (data.item.masterOrderId) {
        this.loadingWidget = true;
        labelDownloadForSelfship(data.item.masterOrderId, data.item.channelOrderId)
          .then((res) => {
            this.loadingWidget = false;
            // location.href=res.label_url;
          })
          .catch((err) => {
            var self = this;
            var myReader = new FileReader();
            myReader.addEventListener("loadend", function (e) {
              self.alertMsg = JSON.parse(e.srcElement.result).detail;
            });
            myReader.readAsText(err);

            this.loadingWidget = false;
            this.alert = true;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
        }
      }
      
    },
    getStatusClass(status) {
      switch (status) {
        case "Shipped":
          return "shipped-status-class";
        case "Unshipped":
          return "unshipped-status-class";
        case "Canceled":
          return "canceled-status-class";
        case "Pending":
          return "pending-status-class";
        default:
          return "";
      }
    },
    getShipmentById() {
      getShipmentById(this.companyID, this.orderShipmentBatchId)
        .then((res) => {
          var {
            orderShipmentBatchId,
            status,
            channelName,
            createdOn,
            shipmentType,
            orderIds,
            channelId,
            pickupSlot,
          } = res;
          this.shipmentTabs[0].value = orderShipmentBatchId;
          this.shipmentTabs[1].value = status;
          this.shipmentTabs[2].value = channelName;
          this.shipmentTabs[3].value = moment(createdOn).format("DD-MM-YYYY");
          this.shipmentTabs[4].value = shipmentType;
          this.shipmentTabs[5].value = orderIds.length;
          this.channelId = channelId;
          this.pickUpSlotDATA = pickupSlot;
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getOrderList() {
      this.tableLoading = true;
      var time = new Date();
      var timeZoneOffset = time.getTimezoneOffset();
      var timeZone = moment_timezone.tz.guess();
      timeZone = moment_timezone.tz.zone(timeZone).abbr(timeZoneOffset);
      getShipmentListByOrderId(
        this.orderShipmentBatchId,
        this.shimentIDs.companyIds,
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.startDate,
        this.endDate,
        this.pickupStartDate,
        this.pickupEndDate,
        this.selectedStatusList,
        this.orderId,
        this.skuId
      )
        .then((res) => {
          this.tableLoading = false;
          this.tableProperties.items = res;
          this.tableProperties.items.forEach((item) => {
            console.log(item);
            item.selected = false;
            item.createdOn =
              item.createdOn != null
                ? `${this.getPurchaseDateZoneWise(
                    item.createdOn
                  )} (${timeZone})`
                : "-";
            item.deliverByFromDate =
              item.deliverByFromDate != null
                ? `${this.getPurchaseDateZoneWise(
                    item.deliverByFromDate
                  )} (${timeZone})`
                : "-";
            item.deliverByToDate =
              item.deliverByToDate != null
                ? `${this.getPurchaseDateZoneWise(
                    item.deliverByToDate
                  )} (${timeZone})`
                : "-";
            item.purchaseDate =
              item.purchaseDate != null
                ? `${this.getPurchaseDateZoneWise(
                    item.purchaseDate
                  )} (${timeZone})`
                : "-";
            item.shipByFromDate =
              item.shipByFromDate != null
                ? `${this.getPurchaseDateZoneWise(
                    item.shipByFromDate
                  )} (${timeZone})`
                : "-";
            item.shipByToDate =
              item.shipByToDate != null
                ? `${this.getPurchaseDateZoneWise(
                    item.shipByToDate
                  )} (${timeZone})`
                : "-";
          });
          this.tableProperties.allSelected = false;
          // if (!res.errorCode) {
          //   this.tableProperties.items = [];
          //   let resp = res.response;
          //   this.tableProperties.items = resp.content;
          //   this.tableProperties.totalRows = resp.totalElements;
          // } else {
          //   console.log(res);
          // }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getPurchaseDateZoneWise(dateAndTime) {
      let time = moment
        .utc(dateAndTime, "DD-MM-YYYY hh:mm:ss")
        .local()
        .format("DD/MM/YYYY hh:mm A");
      return time;
    },
    getOrderStatusList() {
      getOrderStatus()
        .then((res) => {
          if (!res.errorCode) {
            let resp = res.response;
            resp.forEach((element) => {
              this.orderStatus.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    clearPurchaseDate() {
      this.startDate = "";
      this.endDate = "";
      this.getOrderList();
    },
    getPurchaseDate() {
      if (this.selectedPurchaseDate.length > 1) {
        this.startDate = moment(this.selectedPurchaseDate[0]).valueOf();
        this.endDate = moment(this.selectedPurchaseDate[1]).valueOf();
        this.getOrderList();
      }
    },
    clearPickupDate() {
      this.pickupStartDate = "";
      this.pickupEndDate = "";
      this.getOrderList();
    },
    getPickupDate() {
      this.pickupStartDate = moment(this.selectedPickupDate[0]).valueOf();
      this.pickupEndDate = moment(this.selectedPickupDate[1]).valueOf();
      this.getOrderList();
    },
    getOrderId(event) {
      this.orderId = event;
      this.getOrderList();
    },
    getSkuId(event) {
      this.skuId = event;
      this.getOrderList();
    },
    getSelectedstatus(event) {
      this.selectedStatusList = event;
      this.getOrderList();
    },
    getSelectedTypes(event) {
      this.selectedTypeList = event;
      this.getOrderList();
    },
    getSelectedPaymentTypes(event) {
      this.selectedPaymentList = event;
      this.getOrderList();
    },
    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.tableProperties.allSelected = false;
      this.getOrderList();
    },
    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.tableProperties.allSelected = false;
      this.getOrderList();
    },
    goBackToShipment() {
      if (sessionStorage.getItem("backToPage") == "Order") {
        this.$router.push("/order");
      } else {
        this.$router.push("/shipment");
      }
    },
    confirmOk() {
      if (this.popType === "Shedule") {
        this.sheduleShipment();
      } else if (this.popType === "Delete") {
        this.deleteOrderInShipment();
      }
    },
    showConfirmModal(type) {
      this.alert = true;
      this.alertMsg =
        type == "Delete"
          ? "Are you sure you want to delete order ?"
          : "Are you sure you want to schedule order ?";
      this.alertType = "";
      this.popType = type;
      this.isConfirmAlert = true;
    },
    deleteOrderInShipment() {
      let ordersIds = [];
      this.tableProperties.items.forEach((item) => {
        if (item.selected) {
          ordersIds.push(item.masterOrderId);
        }
      });
      if (ordersIds.length > 0) {
        deleteOrderInShipment(
          this.orderShipmentBatchId,
          this.companyID,
          ordersIds
        )
          .then((res) => {
            this.alert = true;
            this.alertMsg = "Shipment order deleted successfully";
            this.alertType = "";
            this.isConfirmAlert = false;
            this.tableProperties.perPage = 50;
            this.tableProperties.currentPage = 1;
            this.tableProperties.allSelected = false;
            this.getOrderList();
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
            this.getOrderList();
          });
      } else {
        this.alert = true;
        this.alertMsg = "Please select atleast one order to proceed..";
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      }
    },
    shipDetailsSchedulePickUp() {
      let ordersIds = [];
      this.tableProperties.items.forEach((item) => {
        if (item.selected) {
          this.loadingWidget = true;
          ordersIds.push(item.channelOrderId);
        }
      });
      if (ordersIds.length > 0) {
        schedulePickUp(this.companyID, ordersIds)
          .then((res) => {
            this.loadingWidget = false;
            if (!res.errorCode && res.status !== "FAILURE") {
              let resp = res.response || [];
              this.shipmentSheduleList = [];
              resp.forEach((element) => {
                this.shipmentSheduleList.push({
                  text:
                    moment(element.pickupStart).format("DD-MM-YYYY hh:mm:A") +
                    " - " +
                    moment(element.pickupEnd).format("hh:mm:A"),
                  value: {
                    pickupSlotId: element.pickupSlotId,
                    pickupStart: element.pickupStart,
                    pickupEnd: element.pickupEnd,
                  },
                });
              });
              this.shipmentShedulePickup = true;
            } else {
              this.alert = true;
              this.alertMsg = res.failureMessage;
              this.alertType = "Failure";
              this.isConfirmAlert = false;
            }
          })
          .catch((err) => {
            this.loadingWidget = false;
            this.alert = true;
            this.alertMsg = err.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
      } else {
        this.alert = true;
        this.alertMsg = "Please select atleast one order to proceed..";
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      }
    },

    // schedule pickup for shiprocket
    scheduleShipRocketOrder() {
      this.loadingWidget = true;
      scheduleShipRocket(this.shimentIDs.shipmentIds)
        .then((res) => {
          this.loadingWidget = false;
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // download generate manifest
    downloadGenerateManifest() {
      this.loadingWidget = true;
      downloadManifestFile(this.shimentIDs.shipmentIds)
        .then((res) => {
          this.loadingWidget = false;
          if (res.manifest_url) {
            location.href = res.manifest_url;
          }
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // download for confirm shipment
    downloadConfirmShipment() {
      this.loadingWidget = true;
      downloadConfirmShipment(this.shimentIDs.shipmentIds)
        .then((res) => {
          this.loadingWidget = false;
        })
        .catch((err) => {
          var self = this;
          // for read the blob response type
          var myReader = new FileReader();
          myReader.addEventListener("loadend", function (e) {
            self.alertMsg = JSON.parse(e.srcElement.result).message;
          });
          //start the reading process.
          myReader.readAsText(err);
          this.loadingWidget = false;
          this.alert = true;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    goBackToOrder() {
      this.isOrderDetailsTab = false;
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
      this.popType = "";
      // for view pickup detail in selfship
      this.isPickUpDetail = false;
    },
    goToOrderDetailsPage(orderDetails) {
      this.orderDetialsParams = orderDetails;
      this.isOrderDetailsTab = true;
    },
    closeShipmentModal() {
      this.shipmentShedulePickup = false;
      this.selectedShipmentSlot = "";
    },
    sheduleShipment() {
      let ordersIds = [];
      this.alert = false;
      this.tableProperties.items.forEach((item) => {
        if (item.selected) {
          ordersIds.push(item.channelOrderId);
        }
      });
      // this.closeShipmentModal();
      if (ordersIds.length > 0) {
        this.loadingWidget = true;
        schedulePickUpSlot(
          this.selectedShipmentSlot.pickupSlotId,
          this.selectedShipmentSlot.pickupStart,
          this.selectedShipmentSlot.pickupEnd,
          ordersIds,
          this.orderShipmentBatchId,
          this.companyID,
          this.channelId
        )
          .then((res) => {
            this.loadingWidget = false;
            this.alert = true;
            this.alertMsg =
              "Order(s) is sheduled for pickup. You can download the Label and Invoice after 30mins";
            this.alertType = "";
            this.isConfirmAlert = false;
            this.tableProperties.perPage = 50;
            this.tableProperties.currentPage = 1;
            this.tableProperties.allSelected = false;
            this.getOrderList();
            this.closeShipmentModal();
          })
          .catch((err) => {
            this.loadingWidget = false;
            this.alert = true;
            this.alertMsg = err.failureMessage
              ? err.failureMessage
              : "Something went wrong";
            this.alertType = "Failure";
            this.isConfirmAlert = false;
            this.closeShipmentModal();
            this.getOrderList();
          });
      } else {
        this.loadingWidget = false;
        this.alert = true;
        this.alertMsg = "Please select atleast one order to proceed..";
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      }
    },
    getSelectedShipmentShedule(event) {
      this.selectedShipmentSlot = event;
    },
    // download shipment template for marketplace and selfship also

    downloadShipmentTemplate(event) {
      this.loadingWidget = true;
      let ordersIds = [];
      let channelID = [];

      this.tableProperties.items.forEach((item) => {
        channelID.push(item.channelId);
        if (item.selected) {
          ordersIds.push(item.masterOrderId);
        }
      });

      if (event == "MARKETPLACE_LOGISTICS") {
        if (ordersIds.length > 0) {
          downLoadTemplate(ordersIds, channelID[0], this.shipmentTabs[0].value)
            .then((res) => {
              this.loadingWidget = false;
            })
            .catch((err) => {
              // for read the blob response type
              var self = this;
              var myReader = new FileReader();
              myReader.addEventListener("loadend", function (e) {
                self.alertMsg = JSON.parse(e.srcElement.result).message;
              });
              //start the reading process.
              myReader.readAsText(err);
              this.loadingWidget = false;
              this.alert = true;
              this.alertType = "Failure";
              this.isConfirmAlert = false;
            });
        } else {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = "Please select atleast one order to download.";
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        }
      }
      if (event == "SELFSHIP") {
        if (ordersIds.length > 0) {
          downLoadEasyShip(
            ordersIds,
            channelID[0],
            this.shipmentTabs[0].value,
            this.shimentIDs.companyIds
          )
            .then((res) => {
              this.loadingWidget = false;
            })
            .catch((err) => {
              // for read the blob response type
              var self = this;
              var myReader = new FileReader();
              myReader.addEventListener("loadend", function (e) {
                self.alertMsg = JSON.parse(e.srcElement.result).message;
              });
              //start the reading process.
              myReader.readAsText(err);
              this.loadingWidget = false;
              this.alert = true;
              this.alertType = "Failure";
              this.isConfirmAlert = false;
            });
        } else {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = "Please select atleast one order to download.";
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        }
      }
    },

    // for view link in selfship shipment detail table
    showPickUpTimeDetail(event) {
      this.loadingWidget = true;
      viewPickSlotDetails(event.masterOrderId)
        .then((res) => {
          this.isPickUpDetail = true;
          this.loadingWidget = false;
          if (res) {
            this.pickUPDetails = res;
            // console.log("pickup detail ", res);
          }
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // open desktop filter
    openDesktop_filter() {
      this.isDesktopFilter = !this.isDesktopFilter;
    },

    // cancel order
    cancelShipmentOrders() {
      let ordersIds = [];
      this.tableProperties.items.forEach((item) => {
        if (item.selected) {
          this.loadingWidget = true;
          ordersIds.push(item.masterOrderId);
        }
      });
      if (ordersIds.length > 0) {
        cancelShipmentOrders(ordersIds)
          .then((res) => {
            this.loadingWidget = false;
          })
          .catch((err) => {
            this.loadingWidget = false;
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
      } else {
        this.alert = true;
        this.alertMsg = "Please select atleast one order to cancel.";
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      }
    },

    // open upload template modal
    openUploadModel(event) {
      if (event == "easyshipTemplate") {
        this.$bvModal.show("upload-easyship-template");
      }
      if (event == "confirmShipment") {
        this.$bvModal.show("upload-ConfirmShipment");
      }
      if (event == "selfshipTemplate") {
        this.$bvModal.show("upload-SelfshipTemplate");
      }
    },
    goBackToPreviousPage(event) {
      if (event == "easyshipTemplate") {
        this.$bvModal.hide("upload-easyship-template");
      }
      if (event == "confirmShipment") {
        this.$bvModal.hide("upload-ConfirmShipment");
      }
      if (event == "selfshipTemplate") {
        this.$bvModal.hide("upload-SelfshipTemplate");
      }
    },
  },
};
</script>

<style scoped>
.card-padding {
  /* padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0 !important; */
  box-shadow: 0px 1px 3px #00000029;
}
.back-button {
  cursor: pointer;
}
.activeTab {
  background: #2185d0;
  color: #fff !important;
  border-radius: 20px;
}
.button-groups {
  border: 0.7px solid #2185d0;
  border-radius: 30px;
}
.button-groups button {
  font-size: 11px;
  font-weight: 600;
  color: #2185d0;
  border: none;
  width: 175px;
  padding: 7px 20px;
}

.shipment-details {
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 5px;
}
.shipment-details ::v-deep .card-body {
  padding: 9px 30px 10px 25px !important;
}
.shipment-heading-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 11px;
  color: #606060;
}
.shipment-value-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  font-weight: bold;
  color: #1b1c1d;
}
.shipment-filter ::v-deep .form-control {
  height: 37px !important;
}
.shipment-detail-table ::v-deep table thead tr th {
  text-align: center !important;
}
.shipment-detail-table ::v-deep table tbody tr td {
  text-align: center !important;
}
.shipment-detail-table ::v-deep table thead tr th:nth-child(2) {
  text-align: left !important;
}
.shipment-detail-table ::v-deep table tbody tr td:nth-child(2) {
  text-align: left !important;
}

@media screen and (max-width: 800px) {
  .whithout-tab-content {
    position: relative;
    top: -40px;
  }
  .detail-column div:nth-child(even) {
    padding-left: 40px !important;
  }
  ::v-deep .modal-content {
    margin-top: 16% !important;
  }
}

@media screen and (max-width: 768px) {
  .shipBy-date ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  .shipBy-date ::v-deep .mx-calendar {
    width: 100%;
  }
  .shipBy-date ::v-deep .mx-calendar-content {
    width: 100%;
  }
  .shipBy-date ::v-deep .mx-datepicker-popup {
    left: 0px !important;
  }
}

.close-filter-icon{
  cursor: pointer;
  position: absolute;
  right: 10px;
  z-index: 2;
  top:0;
}
</style>
